import { gql } from '@apollo/client'

export const FETCH_INSTANCES = gql`
  query instances($reservedBy: String) {
    instances(reservedBy: $reservedBy) {
      name
      country
      number
      status
      domain
      latestSemaphoreBuilds {
        app
        appType
        branch
        updatedAt
      }
      latestJenkinsBuilds {
        app
        appType
        revision
        updatedAt
      }
      activeReservation {
        username
        activeUntil
      }
    }
  }
`

export const FETCH_INSTANCE = gql`
  query instance($name: String!) {
    instance(name: $name) {
      name
      country
      number
      status
      domain
      latestSemaphoreBuilds {
        app
        appType
        appUrl
        branch
        revision
        updatedAt
      }
      latestJenkinsBuilds {
        app
        appType
        branch
        revision
        updatedAt
      }
      activeReservation {
        username
        activeUntil
      }
      reservations(last: 8) {
        edges {
          node {
            id
            username
            active
            activeFrom
            activeUntil
            label
          }
        }
      }
    }
  }
`

export const FETCH_INSTANCE_STATUS = gql`
  query instance($name: String!) {
    instance(name: $name) {
      status
    }
  }
`

export const START_INSTANCE = gql`
  mutation startInstance($name: String!) {
    instanceStart(name: $name) {
      success
    }
  }
`

export const STOP_INSTANCE = gql`
  mutation stopInstance($name: String!) {
    instanceStop(name: $name) {
      success
    }
  }
`

export const RESERVE_INSTANCE = gql`
  mutation reserveInstance($name: String!, $username: String, $activeUntil: ISO8601DateTime, $label: String) {
    instanceReserve(name: $name, username: $username, activeUntil: $activeUntil, label: $label) {
      success
    }
  }
`
